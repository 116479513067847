import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;

    input {
      -webkit-appearance: none;
    }

    input::-webkit-date-and-time-value{
      text-align:left;
    }

    input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
  }

  body {
    padding-bottom: 120px;
    font-family: ${({ theme }) => theme.primaryFont};
    font-weight: 400;
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.text};
    
    a {
      color: ${({ theme }) => theme.text};
      text-decoration: none;
    }

    h1, h2, h3 {
      font-family: ${({ theme }) => theme.titleFont};
    }

    @media(min-width: 800px) {
      padding-bottom: 0;
    }
  }

  #root {
    min-height: calc(100svh - 120px);
    display: flex;
    justify-content: center;
    
    @media(min-width: 800px) {
      min-height: 100vh;
      justify-content: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-bottom: 70px;
    }

    & > div, & > nav {
      padding: ${({ theme }) => theme.appPadding};
      width: 100%;
      box-sizing: border-box;

      @media(min-width: 800px) {
        padding: ${({ theme }) => theme.appMediumPadding};
      }
      @media(min-width: 1000px) {
        padding: ${({ theme }) => theme.appDesktopPadding};
      }

      .page-container {
        display: flex;
        justify-content: center;

        @media(min-width: 800px) {
          background: ${({ theme }) => theme.card};
          padding: 30px;
          border-radius: 15px;
        }
      }
    }
  }
`;